<template>
  <div>
    <AbstractDataTable
      :headers="tableHeaders"
      :items="list"
      :total-count="totalCount"
      :current-page="currentPage"
      :on-page-change="onPageChange"
      :button-required-permissions="requiredPermissions"
      store-name="embeddedApplication"
      table-css-class="embedded-application-table"
      :show-detail-button="() => false"
    >
      <template #tableBody="{ item }">
        <TitleColumn :title-header="tableHeaders[0].name" :title-value="item.id"/>
        <TitleColumn :title-header="tableHeaders[1].name" :title-value="item.displayName"/>
        <TitleColumn :title-header="tableHeaders[2].name" :title-value="item.identifier"/>
        <TitleColumn
          :title-header="tableHeaders[2].name"
          :title-value="item.isActive ? $t('embeddedApplication.active') : $t('embeddedApplication.inactive')"
        />
      </template>

      <template #actions="{ item }">
        <ButtonTableAction
          v-if="isButtonVisible(requiredPermissions.editButton)"
          icon="edit"
          @click.stop="handleEdit(item)"
        />

        <ButtonTableAction
          v-if="isButtonVisible(requiredPermissions.deleteButton)"
          icon="delete"
          @click.stop="handleDelete(item)"
        />
      </template>
    </AbstractDataTable>

    <ConfirmDeleteModal
      v-if="showModal"
      :embedded-application-id="selectedItem.id"
      enable-delete-used
      @close="closeModal"
      @submit="confirmDelete"
    />
  </div>
</template>

<script>
import AbstractDataTable from '@/components/table/AbstractDataTable'
import TitleColumn from '@/components/table/columns/TitleColumn'
import PermissionService from '@/services/PermissionService'
import ButtonTableAction from '@/components/buttons/ButtonTableAction'
import ConfirmDeleteModal from '@/components/embeddedApplication/ConfirmDeleteModal'

export default {
  name: 'EmbeddedApplicationTable',
  props: {
    list: {
      type: Array,
      required: true
    },
    totalCount: {
      type: [Number, String],
      default: 0
    },
    currentPage: {
      type: Number,
      required: true
    },
    onPageChange: {
      type: Function,
      required: true
    }
  },
  components: {
    ConfirmDeleteModal,
    ButtonTableAction,
    AbstractDataTable,
    TitleColumn
  },
  data () {
    return {
      tableHeaders: [
        { name: 'Id' },
        { name: this.$t('embeddedApplication.title') },
        { name: this.$t('embeddedApplication.identifier') },
        { name: this.$t('embeddedApplication.active') },
        { name: this.$t('actions') }
      ],
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.EMBEDDED_APPLICATION_PERMISSIONS,
      showModal: false,
      selectedItem: null
    }
  },
  methods: {
    handleEdit (item) {
      this.$router.push({ name: 'embedded_application_edit', params: { id: item.id } })
    },
    async handleDelete (item) {
      this.selectedItem = item
      this.showModal = true
    },
    async confirmDelete () {
      await this.$store.dispatch('embeddedApplication/deleteRecord', this.selectedItem)
      await this.$store.dispatch('embeddedApplication/fetch')
      this.closeModal()
    },
    closeModal () {
      this.showModal = false
      this.selectedItem = null
    },
    hasPermission (requiredPermissions) {
      return this.$store.getters['user/hasPermission'](requiredPermissions)
    },
    isButtonVisible (permission) {
      return this.hasPermission(permission)
    },
    isDeleteButtonVisible () {
      return this.hasPermission(this.requiredPermissions.deleteButton)
    }
  }
}
</script>
<style lang="scss" scoped>
.embedded-application-table {
  ::v-deep &__thead,
  ::v-deep &__tr {
    @include bp(12) {
      grid-template-columns: rem(80px) 1fr 1fr rem(130px) min(60px);
    }
    @include bp(14) {
      grid-template-columns: rem(80px) 1fr 1fr rem(130px) rem(138px);
    }
  }
}
</style>
