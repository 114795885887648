<template>
  <Modal
    modal-size="modal-md"
    :title="$tc('modal.delete_modal_header')"
    class="embedded-application-modal"
    @close="closeModal"
  >
    <template #body>
      <Preloader v-if="callingAPI" on-top/>

      <div v-else class="embedded-application-modal__body">
        <span>{{ $t('modal.delete_modal_text') }}</span>

        <div v-if="articleList.length" class="mt-4">
          <span>{{ $t('embeddedApplication.modal.article_info') }}</span>

          <ul class="mt-2">
            <li v-for="article in articleList" :key="article.id">
              {{ article.id + ': ' + article.title }}
            </li>
          </ul>
        </div>
      </div>
    </template>

    <template #footer>
      <button type="button" class="btn btn-inverse router-link-active" @click="closeModal" :disabled="isLoading">
        {{ $t('modal.delete_modal_cancel_btn') }}
      </button>

      <button type="button" class="btn btn-danger" @click="submit" :disabled="isLoading || (articleList.length > 0 && !enableDeleteUsed )">
        {{ $t('modal.delete_modal_confirm_btn') }}
      </button>
    </template>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal.vue'
import Preloader from '@/components/preloader/Preloader.vue'
import { mapState } from 'vuex'
import { API_NAME } from '@/store/modules/articleStore'
import coreApi from '@/api/core'

export default {
  name: 'ConfirmDeleteModal',
  components: { Preloader, Modal },
  props: {
    embeddedApplicationId: {
      type: Number,
      required: true
    },
    enableDeleteUsed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: true,
      articleList: []
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  async mounted () {
    try {
      const response = await coreApi().get(API_NAME + '/embedded-application-usages/' + this.embeddedApplicationId)
      this.articleList = response.data?.data
    } catch (error) {
      console.error(error.message)
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    closeModal () {
      this.$emit('close')
    },
    submit () {
      this.$emit('submit')
    }
  }
}
</script>

<style scoped lang="scss">
.embedded-application-modal {
  &__body {
    display: flex;
    flex-direction: column;
    gap: rem(8px);
  }

  .btn {
    &:disabled {
      pointer-events: none;
    }
  }
}
</style>
