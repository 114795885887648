<template>
  <AbstractFilter
    :on-filter="submit"
    :on-filter-reset="resetFilter"
    :button-required-permissions="requiredPermissions"
    filter-panel-class="filter-panel-embedded-application"
    create-new-route-name="embedded_application_new"
  >
    <AppInput
      v-model="filter.displayName"
      id="filter_display_name"
      :label="$t('embeddedApplication.title')"
    />

    <AppInput
      v-model="filter.identifier"
      id="filter_identifier"
      :label="$t('embeddedApplication.identifier')"
    />

    <AppSelect
      v-model="filter.isActive"
      :options="optionsActive"
      :label="$t('embeddedApplication.active')"
      id="filter_active"
    />
  </AbstractFilter>
</template>

<script>
import EmbeddedApplicationFilter from '@/model/EmbeddedApplicationFilter'
import Select from '@/components/form/select/Select.vue'
import Input from '@/components/form/inputs/Input'
import PermissionService from '@/services/PermissionService'
import AbstractFilter from '@/components/filter/AbstractFilter.vue'

export default {
  name: 'FilterEmbeddedApplication',
  components: {
    AbstractFilter,
    AppSelect: Select,
    AppInput: Input
  },
  data () {
    return {
      filter: this._.cloneDeep(EmbeddedApplicationFilter),
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.EMBEDDED_APPLICATION_PERMISSIONS
    }
  },
  computed: {
    optionsActive () {
      return [
        { id: 1, title: this.$t('embeddedApplication.active') },
        { id: 0, title: this.$t('embeddedApplication.inactive') }
      ]
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['embeddedApplication/filter']
  },
  methods: {
    submit () {
      this.$store.commit('embeddedApplication/setPage', 1)
      this.$store.commit('embeddedApplication/setFilter', this.filter)
      this.$store.dispatch('embeddedApplication/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(EmbeddedApplicationFilter)
      this.$store.commit('embeddedApplication/setPage', 1)
      this.$store.commit('embeddedApplication/resetFilter')
      this.$store.dispatch('embeddedApplication/fetch')
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .filter-panel-embedded-application {
  grid-template-columns: repeat(3, 1fr);
}
</style>
