<template>
  <ModuleView>
    <template v-slot:body>
      <div class="card">
        <FilterEmbeddedApplication class="p-4"/>
      </div>

      <Preloader v-if="callingAPI"/>

      <EmbeddedApplicationTable
        :list="list"
        :totalCount="totalCount"
        :current-page="page"
        :on-page-change="setPageAndGetRecords"
      />
    </template>
  </ModuleView>
</template>

<script>
import { mapState } from 'vuex'
import ModuleView from '@/components/ModuleView'
import FilterEmbeddedApplication from '@/components/filter/FilterEmbeddedApplication'
import Preloader from '@/components/preloader/Preloader'
import EmbeddedApplicationTable from '@/components/table/EmbeddedApplicationTable'

export default {
  name: 'EmbeddedApplicationListView',
  components: { EmbeddedApplicationTable, Preloader, FilterEmbeddedApplication, ModuleView },
  data () {
    return {}
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    list () {
      return this.$store.getters['embeddedApplication/list']
    },
    totalCount () {
      return this.$store.getters['embeddedApplication/totalCount']
    },
    page () {
      return this.$store.getters['embeddedApplication/page']
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](permission)
    },
    async getList () {
      await this.$store.dispatch('embeddedApplication/fetch')
      this.dataLoaded = true
    },
    setPageAndGetRecords (page) {
      this.$store.commit('embeddedApplication/setPage', page)
      this.getList()
    }
  }
}
</script>

<style scoped lang="scss">
</style>
